<template>
  <div id="app">
    <div>
      <div class="card-header-title header-style" :style="headerStyleObject">
        <a :href="websiteUrl" target="_new"
          ><img
            :src="`img/${$omwConfig.display.companyLogo}`"
            :style="headerImageStyleObject"
            class="has-text-left"
          />
        </a>
      </div>
      <div v-if="separatorEnabled" class="header-separator" />
    </div>
    <div class="app-background pb-2 main-content">
      <div v-if="separatorEnabled" style="height: 2em" class="app-background" />
      <router-view />
    </div>
    <the-footer class="footer" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';

export default defineComponent({
  components: {
    TheFooter: () =>
      import(/* webpackPrefetch: true */ '@/components/layout/TheFooter.vue'),
  },
  data() {
    return {
      headerStyleObject: {
        height: this.$omwConfig.display.headerHeight,
      },
      headerImageStyleObject: {
        height: this.$omwConfig.display.imageHeight,
        width: this.$omwConfig.display.imageWidth,
        marginTop: this.$omwConfig.display.headerLogoMarginTop,
      },
      websiteUrl: this.$omwConfig.display.customerWebsite,
    };
  },
  computed: {
    ...mapGetters(['token']),
    separatorPadding() {
      if (this.separatorEnabled) {
        return { 'margin-top': '2em' };
      } else return '';
    },
  },
  beforeCreate() {
    if (this.$omwConfig?.trustpilot?.enabled) {
      const trustpilotScript = document.createElement('script');
      trustpilotScript.setAttribute(
        'src',
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
      );
      trustpilotScript.setAttribute('async', 'async');
      document.head.appendChild(trustpilotScript);
    }
  },
  async created() {
    if (!this.token && !this?.$route?.query?.token) {
      this.$router.replace({ name: 'Error' }).catch(() => {
        /** No op */
      });
      return;
    }
    if (!this.token) {
      await this.setToken(this.$route.query.token);
    }
    try {
      this.setLoading(true);
      await this.setOmwData();
    } catch (err) {
      await this.setToken(undefined);
      this.$router.replace({
        name: 'Error',
      });
    } finally {
      this.setLoading(false);
    }

    if (this.$route.query.marketingPreviewUrl) {
      // If this being called as result of a preview from the portal, propagate the preview url
      query.marketingPreviewUrl = this.$route.query.marketingPreviewUrl;
      query.clickUrl = this.$route.query.clickUrl;
    }
    this.$router
      .push({
        name: 'Home',
        query: {
          token: this.token,
          contactCentre: this.$route.query.contactCentre,
        },
      })
      .catch((err) => {
        /* noop */
      });
  },
  methods: {
    ...mapActions(['setOmwData', 'setLoading', 'setToken']),
    isInPast() {
      const scheduledDate = DateTime.fromISO(this.activityDetails.date);
      const diff = scheduledDate.startOf('day').diffNow('day');
      return diff.days < -1;
    },
  },
});
</script>

<style lang="scss">
@import '@/sass/buefy-init.scss';
@import '@/sass/variables.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@if variable-exists(button-border-radius) {
  button {
    border-radius: $button-border-radius !important;
  }
}

.header-style {
  margin-top: 0.5em;
  background-color: $header-background;
}
.app-background {
  background-color: $main-background;
  color: $font-color;
}
.header-separator {
  background-color: $separator-color;
  width: 100vw;
  height: 30px;
}
.main-content {
  min-height: calc(100vh - 128px);
}
.footer {
  height: 50px;
}
</style>
