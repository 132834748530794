<template>
  <div>
    <custom-loading v-if="isLoading" :key="'loading'" />
    <div>
      <transition-group v-if="!isLoading" name="fade" style="width: 100%">
        <div :key="'maingroup'">
          <main-container class="mx-2" :style="headerPadding" />
          <feedback-container
            v-if="feedbackEnabled"
            feedbackstyle="cursor: pointer;"
            @feedback="recordFeedback"
            @feedback-active="setHideIntercom"
          />
        </div>
      </transition-group>
    </div>
    <div
      v-once
      id="notificationContainer"
      ref="notificationContainer"
      class="notification-container"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapActions, mapGetters } from 'vuex';
import MainContainer from '@/components/MainContainer.vue';
import CustomLoading from '@/components/CustomLoading.vue';
import { submitFeedback } from '@/services/feedback';
import { DateTime } from 'luxon';

export default defineComponent({
  name: 'Home',
  components: {
    MainContainer,
    CustomLoading,
    FeedbackContainer: () =>
      import(
        /* webpackPrefetch: true */ '@/components/optional/feedback/FeedbackContainer.vue'
      ),
  },
  data() {
    return {
      hideIntercomLauncher: false,
      isIntercomOnlineBucket: false,
      intervalHandle: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'activityDetails',
      'shouldShowIntercom',
      'dismissNotification',
      'token',
      'isLoading',
    ]),
    headerPadding() {
      if (this.isMobile)
        return {
          'margin-top': '-1rem',
        };
      return {
        'padding-top': '10px',
      };
    },
    isIntercomOnlineDay() {
      if (
        !this.$omwConfig.intercom ||
        !this.$omwConfig.intercom.enabled ||
        !this.$omwConfig.intercom.onlineDays
      )
        return false;
      const todayDay = DateTime.now().weekday;
      const onlineDays = Object.keys(this.$omwConfig.intercom.onlineDays);
      if (onlineDays.includes(todayDay + '')) {
        return true;
      } else {
        return false;
      }
    },
    intercomOnlineTime() {
      if (this.$omwConfig.intercom.enabled) {
        const now = DateTime.now();
        const day = now.weekday;
        const showTimeToday = this.$omwConfig.intercom.onlineDays[day];
        if (!showTimeToday) return null;
        const timeParts = showTimeToday.showTime.split(':');
        const onlineTime = now.set({
          hours: parseInt(timeParts[0]),
          minutes: parseInt(timeParts[1]),
          seconds: 0,
        });
        return onlineTime;
      }
      return null;
    },
    intercomOfflineTime() {
      if (this.$omwConfig.intercom.enabled) {
        const now = DateTime.now();
        const day = now.weekday;
        const hideTimeToday = this.$omwConfig.intercom.onlineDays[day];
        if (!hideTimeToday) return null;
        const timeParts = hideTimeToday.hideTime.split(':');
        const offlineTime = now.set({
          hours: parseInt(timeParts[0]),
          minutes: parseInt(timeParts[1]),
          seconds: 0,
        });
        return offlineTime;
      }
      return null;
    },
  },
  watch: {
    hideIntercomLauncher: {
      handler(newVal) {
        if (!this.$omwConfig.intercom.enabled) {
          return;
        }
        // If value is actually changing and not from an immediate boot
        if (newVal === false) {
          setTimeout(() => {
            this.bootIntercom(newVal);
          }, this.$omwConfig.feedback.timeout);
        } else {
          this.bootIntercom(newVal);
        }
      },
    },
    activityDetails: {
      immediate: true,
      handler() {
        if (!this.$omwConfig?.intercom?.onlineBuckets) {
          this.isIntercomOnlineBucket = true;
          return;
        }
        this.isIntercomOnlineBucket =
          this.$omwConfig.intercom.onlineBuckets.includes(
            this.engineerDetails.parentId,
          );
      },
    },
  },
  async created() {
    this.currentDate = new Date();
    if (this.$omwConfig.intercom.enabled) {
      this.calculateIfOnline();
      this.bootIntercom(false);
      this.intervalHandle = setInterval(async () => {
        this.calculateIfOnline();
        this.bootIntercom(this.hideIntercomLauncher);
      }, 5000);
    }
    this.handleShowNotification();
  },
  async mounted() {
    if (!this.token) {
      this.setLoading(false);
      this.$router.push({ name: 'Error' });
    }
  },
  beforeDestroy() {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
    }
  },
  methods: {
    ...mapActions([
      'setCurrentDate',
      'setShouldShowIntercom',
      'setDismissNotification',
      'setLoading',
    ]),
    handleShowNotification() {
      if (this.notificationEnabled && !this.dismissNotification) {
        this.$buefy.dialog.alert({
          title: this.$t('notification-title'),
          message: this.$t('notification-message'),
          type: 'is-info',
          ariaRole: 'alertdialog',
          ariaModal: true,
          onConfirm: () => {
            this.setDismissNotification(true);
          },
          size: 'is-medium',
          container: 'notificationContainer',
        });
      }
    },
    setHideIntercom(evt) {
      this.hideIntercomLauncher = evt;
    },
    bootIntercom(shouldHide) {
      // We're outside of defined hours so we shouldn't show
      if (!this.shouldShowIntercom) {
        this.$nextTick(() => {
          this.$intercom.boot({
            ApptNo: this.activityDetails.apptNumber,
            Bucket: this.engineerDetails.parentId,
            hide_default_launcher: true,
          });
        });
      } else {
        this.$nextTick(() => {
          this.$intercom.boot({
            ApptNo: this.activityDetails.apptNumber,
            Bucket: this.engineerDetails.parentId,
            hide_default_launcher: shouldHide,
            OMW: window.location.href,
          });
          this.$intercom.update({
            ApptNo: this.activityDetails.apptNumber,
            Bucket: this.engineerDetails.parentId,
            OMW: window.location.href,
          });
        });
      }
    },
    calculateIfOnline() {
      const now = DateTime.now();
      if (!this.isIntercomOnlineBucket) {
        return false;
      }
      if (!this.intercomOnlineTime || !this.intercomOfflineTime) {
        return false;
      }
      // First check it's a day when intercom should show
      if (!this.isIntercomOnlineDay) return false;
      const diffStartToNow = this.intercomOnlineTime.diff(now, 'minutes');
      const diffNowToEnd = now.diff(this.intercomOfflineTime, 'minutes');
      if (diffStartToNow <= 0 && diffNowToEnd <= 0) {
        this.setShouldShowIntercom(true);
      } else {
        this.setShouldShowIntercom(false);
      }
    },
    async recordFeedback(evt) {
      const feedbackResponse = evt;
      feedbackResponse.appointmentNumber = this.activityDetails.apptNumber;
      feedbackResponse.feedbackDate = new Date().valueOf();
      feedbackResponse.token = this.token;
      feedbackResponse.url = window.location.href;
      try {
        await submitFeedback(feedbackResponse);
      } catch (err) {
        console.log(err);
        this.$buefy.toast.open({
          message: this.$t(
            'Something went wrong when submitting your feedback, please try again',
          ),
          type: 'is-danger',
          duration: this.$omwConfig.feedback.timeout,
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.notification-container {
  position: fixed;
  width: 80vw;
  z-index: 999;
}
</style>
