import { DateTime } from 'luxon';
import configData from '@/config/config';

const TIME_FORMAT = configData.display.timeFormat || 'HH:mm';

export function calculateEtaBuffer(etaDateTime) {
  const bufferedStartDate = DateTime.fromISO(etaDateTime)
    .minus({
      minutes: configData.display.eta.bufferBeforeMins,
    })
    .toFormat(TIME_FORMAT);
  const bufferedEndDate = DateTime.fromISO(etaDateTime)
    .plus({
      minutes: configData.display.eta.bufferBeforeMins,
    })
    .toFormat(TIME_FORMAT);
  return {
    bufferedStartDate,
    bufferedEndDate,
  };
}
