<template>
  <b-button class="is-home-button has-text-weight-semibold" @click="goHome">{{
    $t('rebook-home-button-label')
  }}</b-button>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'AppointmentHomeButton',
  methods: {
    goHome() {
      this.$emit('home');
    },
  },
});
</script>
