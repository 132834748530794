<script>
/**
 * Global mixin to provide helper utilities
 */
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      engineerIconWidth: this.$omwConfig.display.engineerIcon.width,
      engineerIconHeight: this.$omwConfig.display.engineerIcon.height,
      engineerIconAnchorX: this.$omwConfig.display.engineerIcon.anchorX,
      engineerIconAnchorY: this.$omwConfig.display.engineerIcon.anchorY,
      activityIconWidth: this.$omwConfig.display.activityIcon.width,
      activityIconHeight: this.$omwConfig.display.activityIcon.height,
      activityIconAnchorX: this.$omwConfig.display.activityIcon.anchorX,
      activityIconAnchorY: this.$omwConfig.display.activityIcon.anchorY,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(['activityDetails', 'engineerDetails']),
    language() {
      const lang = navigator.language;
      return lang;
    },
    separatorEnabled() {
      return this.$omwConfig?.display.separatorEnabled;
    },
    feedbackEnabled() {
      return this.$omwConfig?.feedback?.enabled;
    },
    notificationEnabled() {
      return this.$omwConfig?.display?.notification?.show;
    },
    engineerName() {
      if (this.apptInFuture) {
        return this.$t('future-engineer-name');
      }
      return (
        this.engineerDetails.nameOverride ||
        this.engineerDetails.name ||
        this.$t('unknown-engineer-name')
      );
    },
    isMobile() {
      return this.windowWidth <= 768;
    },
    showDisclaimer() {
      return (
        this.activityDetails &&
        this.activityDetails.status !== 'completed' &&
        this.activityDetails.status !== 'started'
      );
    },
    activityIcon() {
      return this.$omwConfig.display.activityIcon.svg;
    },
    engineerIcon() {
      return this.$omwConfig.display.engineerIcon.svg;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  mounted() {
    window.addEventListener('resize', this.widthListenerCallBack);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.widthListenerCallBack);
  },
  methods: {
    getOrientation(x) {
      if (x === undefined) {
        return 'desktop';
      } else {
        let y;
        if (x === 90 || x === -90) {
          y = 'landscape';
        } else {
          y = 'portrait';
        }
        return y;
      }
    },
    widthListenerCallBack() {
      this.windowWidth = window.innerWidth;
    },
    convertTrueFalseStringToBoolean(trueFalse) {
      if (trueFalse === undefined) return undefined;
      if (!trueFalse) {
        return false;
      }
      if (trueFalse.toLowerCase() === 'true') {
        return true;
      }
      return false;
    },
  },
};
</script>
