import omwConfig from '@/config/config';
import axios from 'axios';

export async function submitFeedback(data) {
  const url = omwConfig.feedback.url;
  const options = {
    method: 'post',
    data,
    timeout: 3000,
  };
  return axios.request(url, options);
}
