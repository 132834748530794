<template>
  <div>
    <div class="centered mt-5">
      <img
        :src="`img/${$omwConfig.display.companyLogo}`"
        style="width: 20vw; padding-top: 5em"
        class="px-3 py-2 logo-background"
      />
    </div>
    <b-loading :is-full-page="true" :active.sync="isLoading" />

    <p
      class="
        has-text-weight-semibold
        is-size-4
        has-text-grey has-text-centered
        is-italic
      "
      style="padding-top: 5em"
    >
      {{ $t('app-finding-appointment-text') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  computed: {
    ...mapGetters(['isLoading']),
  },
});
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-background {
  background-color: $header-background;
}
</style>
