var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"confirmation",staticStyle:{"margin-left":"auto","margin-right":"auto"}},[_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t('rebook-confirmation', {
          apptNumber: _vm.activityDetails.apptNumber,
          rescheduleDate: _vm.appointment,
        })
      )}}),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t('rebook-confirm-cancel-contact-us', {
          contactNumber: _vm.$omwConfig.display.contactCentreNo,
        })
      )}}),_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('rebook-update-delay-guidance')))])]),_c('div',{staticClass:"has-text-centered"},[_c('post-process-home-button')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }