import omwConfig from '@/config/config';
import axios from 'axios';
export async function getData(token) {
  const response = await axios.get(`${omwConfig.omwServer.url}=${token}`, {
    headers: {
      'x-api-key': omwConfig.omwServer.apiKey,
    },
    crossdomain: true,
    timeout: 30000,
  });
  if (response.status === 204) throw new Error('No appointment retrieved');
  return response.data;
}
