<template>
  <i class="is-size-5">{{ $t('app-last-updated-text') }} {{ msg }} </i>
</template>

<script>
import { DateTime } from 'luxon';
import formatDistance from '@/utils/formatDistance';

export default {
  props: {
    start: {
      type: Date,
      default: () => new Date(),
    },
    autoUpdate: {
      type: Number,
      default: 60000,
    },
  },
  data() {
    return {
      msg: '',
      intervalHandle: undefined,
    };
  },
  computed: {
    startDateTime() {
      return DateTime.fromISO(this.start.toISOString());
    },
  },
  async created() {
    this.msg = formatDistance(this.startDateTime, DateTime.now());
    this.intervalHandle = setInterval(() => {
      this.msg = formatDistance(this.startDateTime, DateTime.now());
    }, this.autoUpdate);
  },
  beforeDestroy() {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
    }
  },
};
</script>

<style></style>
