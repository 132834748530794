<template functional>
  <div class="columns has-text-left is-mobile legend-panel mb-2">
    <div class="column is-2">
      <div>
        <b-icon icon="truck" type="is-rebook-eco-truck" />
      </div>
    </div>
    <div class="column">
      <p class="slot-text has-text-weight-semibold">
        {{ parent.$t('rebook-legend-eco') }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'AppointmentLegend',
});
</script>

<style lang="scss">
@import '@/sass/variables.scss';

.legend-panel {
  border-radius: 10px;
  padding: 1em;
  border-style: solid;
  border-width: 1px;
  border-color: $rebook-legend-border;
  background-color: $rebook-appointment-slot;
  max-width: 25rem;
  margin: 2rem auto;
}

.slot-text {
  color: $rebook-appointment-slot-text;
}
</style>
