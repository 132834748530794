<template>
  <b-button
    type="is-danger has-text-weight-semibold"
    @click="$emit('cancel')"
    >{{ $t('rebook-cancel-button-label') }}</b-button
  >
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AppointmentCancellationButton',
});
</script>
