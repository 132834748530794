import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import enUS from './enUS';
import de from './de';
import es from './es';
import it from './it';
import fr from './fr';
import nl from './nl';
import dateFormats from './dateFormat';
const dateTimeFormats = dateFormats;

Vue.use(VueI18n);

const locale = navigator.language;

const messages = {
  'en-GB': en,
  en,
  enUS,
  'en-US': enUS,
  de,
  'de-DE': de,
  it,
  fr,
  'fr-FR': fr,
  'es-ES': es,
  es,
  nl,
};

const i18n = new VueI18n({
  locale,
  dateTimeFormats,
  messages,
  fallbackLocale: 'en-GB',
  silentTranslationWarn: true,
});

export default i18n;
