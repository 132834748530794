<template functional>
  <div>
    {{ error }}
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'AppointmentError',
  props: {
    error: {
      type: String,
      required: true,
    },
  },
});
</script>
